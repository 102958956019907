.work-tile-title {
    display: none;
}
      
.work-tile:hover .work-tile-title {
    display: block;
    animation: fadeIn .5s;
}

.work-tile {
    transition: transform .2s;
}

.work-tile:hover {
    transform: scale(1.1);
    position: relative;
    z-index: 100;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
  